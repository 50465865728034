<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='700'>
                    <col width='180'>
                    <col width='160'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">标题</th>
                        <th>时间</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="item  in data" :key="item.Id">
                        <td>
                            <div class="dot" @click="goDetail(item)">{{item.Title}}</div>
                        </td>
                        <td class="center">
                            {{item.UpdateTime.split(' ')[0].split('/').join('-')}}
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="goDetail(item)">详情</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
        />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetDataList } from '@/api/common'
export default {
    metaInfo() {
		return {
			title: this.$route.meta.title || '文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: this.$route.meta.title || '文物出版社'
                },
                {
                    name: 'description',
					content: this.$route.meta.title || '文物出版社'
                }
            ]
		}
	},
    components:{
        Pagination
    },
    data(){
        return{
            data:[],
            params:{
                columnId:10023,
                pageIndex:1,
                pageSize:10
            },
            dialogData:{},
            total:10,
            spinning:false,
            visable:false,
        }
    },
    methods:{
        pageChange(){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetDataList(this.params)
            this.data = data
            this.total = total
            this.spinning = false
        },
        goDetail(item){
            this.$router.push({name:'solutioninfo',params:{id:item.Id}})
        }
    },
    mounted(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>

/deep/.ant-spin-nested-loading{
    padding: 0 40px;
}
.model{
    .table{
        border-top: 0;
    }
}
.th1{
    text-align: left !important;
    padding-left: 30px;
}
td{
    height: 60px !important;
}
.center{
    text-align: center;
}
.dot{
    cursor: pointer;
    &:hover{
        color: #f6a75c;
    }
}
.tdtitle{
    position: relative;
    padding-left: 10px;
    &::before{
        content: '';
        width: 2PX;
        height: 2PX;
        background-color: #555555;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}
</style> 